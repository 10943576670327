import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Grid } from '@material-ui/core';
import LogoMain from '../../assets/svg/logo-full-alt-big.svg';
import DlaczegoWarto from '../components/dlaczegoWarto';
import FeatureSectionDark from '../components/featureSectionDark';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import WordPressIcon from '../../assets/svg/wordpress-plain.svg';
import WorkBoxIcon from '../../assets/svg/workbox.svg';
import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import BackendIcon from '../../assets/svg/aplikacje-web.svg';
import MobileIcon from '../../assets/svg/aplikacje-mobilne.svg';
import WebsitesIcon from '../../assets/svg/strony-internetowe.svg';
import PWAIcon from '../../assets/svg/publikacja-strony-internetowej.svg';

import FeatureSectionLight from '../components/featureSectionLight';
import TechBox from '../components/techBox';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query Images {
            image: file(
                relativePath: { eq: "nowoczesny-design-strony-internetowe.jpg" }
            ) {
                id
                childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            pwa: file(relativePath: { eq: "aplikacje-progresywne-pwa.png" }) {
                id
                childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            aplikacje: file(
                relativePath: { eq: "iqcode-aplikacje-mobilne-dark.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            backend: file(relativePath: { eq: "aplikacje-serwerowe-l.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            erp: file(relativePath: { eq: "systemy-erp.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            kompleksowa: file(
                relativePath: { eq: "programowanie-praca-zespolowa.jpg" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sh: file(relativePath: { eq: "iqcode-software-house-l.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            shppl: file(relativePath: { eq: "nextcloud-bg.jpg" }) {
                id
                childImageSharp {
                    fluid(maxWidth: 2560, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaus: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <>
      <SEO title="Software House w Warszawie - iqcode Technologie Internetowe" description="Software House iqcode tworzy strony internetowe, aplikacje mobilne oraz aplikacje progresywne (PWA) z dodatkową specjalizacją w środowisku Kubernetes." />
      <div
        className="section scrollspy no-pad-bot hero overlay-holder"
        id="agencja-interaktywna"
      >
        <div className="diagonal-overlay" id="do2" />
        <div className="diagonal-overlay" />
        <Img
          fluid={data.shppl.childImageSharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            zIndex: -1,
          }}
          style={{
            height: '100%',
            position: 'absolute',
            width: '100%',
          }}
        />
        <Container maxWidth="xl" className="fh">
          <Grid container alignItems="center" className="fh">
            <Grid className="z-1 intro-outer" item md={12} lg={7}>
              <div className="intro-cont">
                <h1
                  className="intro1"
                >
                  Tworzymy kompleksowe
                  <br />
                  <span>Rozwiązania IT</span>
                  <br />
                  {' '}
                  dla Firm
                </h1>
                <a
                  className="btn btn-alt"
                  href="#o-nas"
                  style={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  onClick={() => {
                    document
                      .querySelector('#stm')
                      .scrollIntoView({
                        behavior: 'smooth',
                      });
                  }}
                >
                  POZNAJ IQCODE
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div id="stm">
        <Container className="mwr">
          <Grid container>
            <Grid item sm={12} md={8}>
              <h2>
                Nowoczesny
                {' '}
                <span>Software House</span>
              </h2>
              <p>
                <strong>iqcode</strong>
                {' '}
                to Software House
                założony w 2018 roku. Od tego czasu
                zrealizowaliśmy liczne projekty softwarowe dla
                klientów począwszy od małych i średnich
                przedsiębiorstw o zasięgu lokalnym, a
                zakończywszy na startupach technologicznych
                działających w modelu SaaS.
              </p>
              <p>
                Tworzymy nowoczesne i skalowalne produkty
                cyfrowe pozbawione długu technologicznego. Do
                obszarów naszej specjalizacji należą progresywne
                aplikacje webowe (PWA), aplikacje serwerowe w
                architekturze mikrousług (
                <i>microservices architecture</i>
                )
                tworzone w Node.js i Pythonie oraz rozwiązania
                DevOps w środowisku systemów rozproszonych
                Kubernetes.
              </p>
            </Grid>
            <Grid item sm={12} md={4} style={{ padding: '30px' }}>
              <LogoMain className="inline-svg-fw" />
            </Grid>
          </Grid>
          <TechBox
            fullWidth
            technologies={[
              {
                icon: <MobileIcon />,
                name: 'Aplikacje mobilne',
                link: '/aplikacje-mobilne',
              },
              {
                icon: <WebsitesIcon />,
                name: 'Strony i serwisy internetowe',
                link: '/strony-internetowe',
              },
              {
                icon: <PWAIcon />,
                name: 'Aplikacje progresywne',
                link: '/aplikacje-progresywne-pwa',
              },
              {
                icon: <BackendIcon />,
                name: 'Aplikacje serwerowe',
                link: '/aplikacje-serwerowe',
              },
            ]}
          />
        </Container>
      </div>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Chcesz nieodpłatnie wycenić
            {' '}
            <span>projekt</span>
            ?
          </h3>
          <Link className="btn" to="/kontakt/">
            Napisz do nas!
          </Link>
        </div>
      </div>
      <DlaczegoWarto />
      <FeatureSectionDark
        title={(
          <>
            Aplikacje
            {' '}
            <span>Mobilne</span>
          </>
                  )}
        subtitle="Nowoczesne i szybkie"
        img={(
          <Img
            alt="nowoczesne aplikacje mobilne"
            fluid={data.aplikacje.childImageSharp.fluid}
            backgroundColor="#252b35"
          />
                )}
        to="/aplikacje-mobilne/"
        link="Dowiedz się więcej"
        technologies={[
          { icon: <ReactIcon />, name: 'React Native' },
          { icon: <FlutterIcon />, name: 'Flutter' },
        ]}
      />
      <FeatureSectionLight
        title={(
          <>
            Strony i Serwisy
            {' '}
            <span>Internetowe</span>
          </>
                  )}
        subtitle="Wykonywane w nowoczesnych technologiach"
        img={(
          <Img
            alt="nowoczesne strony internetowe"
            fluid={data.image.childImageSharp.fluid}
          />
                )}
        to="/strony-internetowe/"
        link="Dowiedz się więcej"
        technologies={[
          { icon: <GatsbyIcon />, name: 'Gatsby.js' },
          {
            icon: <WordPressIcon />,
            name: <>
              Word
              <wbr />
              Press
            </>,
          },
        ]}
      />
      <FeatureSectionDark
        title={(
          <>
            Aplikacje
            <br />
            <span>Progresywne</span>
          </>
                  )}
        subtitle="Nowoczesna alternatywa dla aplikacji mobilnych"
        img={(
          <Img
            alt="Aplikacje progresywne PWA"
            fluid={data.pwa.childImageSharp.fluid}
          />
                )}
        to="/aplikacje-progresywne-pwa/"
        link="Dowiedz się więcej"
        technologies={[
          { icon: <ReactIcon />, name: 'React.js' },
          { icon: <WorkBoxIcon />, name: 'Workbox' },
        ]}
      />
      <FeatureSectionLight
        title={(
          <>
            Aplikacje
            {' '}
            <span>Serwerowe</span>
          </>
                  )}
        subtitle="Skalowalne aplikacje backendowe"
        img={(
          <Img
            alt="Skalowalne aplikacje działające po stronie serwera"
            fluid={data.backend.childImageSharp.fluid}
          />
                )}
        to="/aplikacje-serwerowe/"
        link="Dowiedz się więcej"
        technologies={[
          { icon: <NodeIcon />, name: 'Node.js' },
          { icon: <PythonIcon />, name: 'Python' },
        ]}
      />
      <FeatureSectionDark
        title={(
          <>
            Systemy
            {' '}
            <span>CRM/ERP</span>
          </>
                  )}
        subtitle="Kompleksowe wdrożenia aplikacji biznesowych"
        img={(
          <Img
            alt="systemy CRM i ERP"
            fluid={data.erp.childImageSharp.fluid}
          />
                )}
        to="/systemy-crm-erp/"
        link="Dowiedz się więcej"
        technologies={[
          { icon: <ReactIcon />, name: 'React.js' },
          { icon: <WorkBoxIcon />, name: 'Workbox' },
        ]}
        short
      />
      <FeatureSectionLight
        title={(
          <>
            <span>Kompleksowa </span>
            Realizacja Projektów
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="kompleksowe usługi informatyczne"
              fluid={data.kompleksowaus.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Oferujemy kompleksową realizację produktów cyfrowych
              począwszy od wczesnych etapów
              {' '}
              <strong>projektowania</strong>
              {' '}
              i
              dokumentowania wymagań projektu, poprzez iteracyjne
              prace programistyczne i testy realizowane z
              zastosowaniem
              {' '}
              <strong>metodyk zwinnych</strong>
              , a zakończywszy na
              wdrożeniu, wsparciu technicznym oraz rozbudowie
              produktów.
            </p>
            {' '}
            <p>
              Bogaty zestaw stosowanych technologii pozwala nam na
              realizację złożonych projektów programistycznych łączących
              wiele dziedzin programowania i
              {' '}
              <strong>
                pozbawionych długu
                technologicznego
              </strong>
              .
            </p>
            {' '}
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <AllServices />
    </>
  );
};

export default IndexPage;
